/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useMemo, useEffect } from 'react'
import dynamic from 'next/dynamic'
import {
  BreakPoint,
  megaNavItem,
  MegaNavColumnType,
  MegaNavColumnContent,
  MegaNavColumContentType,
  MegaNavPromotionType,
} from './types'

const Row = dynamic(import('@csc/dls/Row'))
const Heading4 = dynamic(import('@csc/dls/Heading4'))
const Link = dynamic(import('@/components/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))

export type SiblingReferer = {
  siblingsRefs: Array<{ ref: HTMLLIElement | null, setter: ((input: HTMLLIElement) => void) }>
  megaNavTrackRef?: HTMLElement | null,
}
export type MenuEventsType = {
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  onFocus: () => void,
  onBlur: React.FocusEventHandler<Element>
}

const isDefined = (i: unknown) => !!i
interface MegaNavLinkProps extends MegaNavColumContentType {
  menuEvents: MenuEventsType,
  isMainLink?: boolean,
}

const MegaNavLink: React.FC<MegaNavLinkProps> = ({
  title = '',
  url = '',
  newTab = false,
  isMainLink = false,
  className = `no-underline outline-none min-h-5 box-border ${isMainLink ? 'mb-4' : 'mb-2'} ${url ? 'hover:border-black focus:border-black active:border-black' : ''}`,
  size = 'md',
  boldText = false,
  textColor = '',
  style = textColor ? { color: textColor } : undefined,
  linkProps = {
    className: !url ? 'cursor-default' : 'cursor-pointer',
    href: url,
    target: newTab ? '_blank' : '_self',
    alt: title,
  },
  menuEvents,
}) => {
  const Component = boldText || isMainLink ? Heading4 : Text
  return (
    !title ? (
      null
    ) : (
      <Component
        style={style}
        className={className}
        size={size}
        bold={boldText || isMainLink}
        aria-label={title}
      >
        <Link
          href={url}
          role="treeitem"
          underline={false}
          {...linkProps}
          onMouseEnter={menuEvents.onMouseEnter}
          onFocus={menuEvents.onFocus}
          onBlur={menuEvents.onBlur}
        >
          {title}
        </Link>
      </Component>
    )
  )
}

const MegaNavColumnGroup: React.FC<
MegaNavColumnContent & { menuEvents: MenuEventsType, index: number }> = ({
  links = [],
  mainLink,
  index,
  menuEvents,
}) => (
  <div
    className="flex-column relative mb-8 last-of-type:mb-0"
    key={`Desktop-MegaNavColumnGroup-${index}`}
  >
    {isDefined(mainLink) && (
      <MegaNavLink
        menuEvents={menuEvents}
        {...mainLink}
        boldText
        isMainLink
      />
    )}
    {links?.filter(isDefined)?.map((link) => (
      <MegaNavLink
        key={`${String(link.url)}-${link.title}`}
        menuEvents={menuEvents}
        {...link}
      />
    ))}
  </div>
)
interface MegaNavColumnProps extends MegaNavColumnType {
  menuEvents: MenuEventsType,
  index: number,
  isLastColumn?: boolean
}

const MegaNavColumn: React.FC<MegaNavColumnProps> = ({
  content = [],
  menuEvents,
  index,
  isLastColumn = false,
}) => (
  !Array.isArray(content) ? null : (
    <div
      className="w-51.25 text-left min-w-51.25, max-w-51.25 justify-around"
      key={`Desktop-MegaNavColumn-${index}`}
    >
      <div className={isLastColumn ? '' : 'mr-14.5'}>
        {content?.filter(isDefined)?.map((c, i) => (
          <MegaNavColumnGroup
            {...c}
            menuEvents={menuEvents}
            index={i}
            key={
              typeof c === 'object'
                ? JSON.stringify(c)
                : String(c)
              }
          />
        ))}
      </div>
    </div>
  )
)

const PromotionColumn: React.FC<MegaNavPromotionType> = ({
  imageUrl,
  url,
  title,
  newTab = false,
  body,
  callToActionText,
}) => (
  <div className="text-left pl-3 min-w-65 max-w-65 justify-around">
    <Row cols={1}>
      {imageUrl && (
        <Link
          href={url}
          underline={false}
          target={newTab ? '_blank' : '_self'}
          rel={newTab ? 'noreferrer' : undefined}
        >
          <img src={imageUrl} alt={`promotion ${title || ''}`} className="max-w-full" />
        </Link>
      )}

      {!!title && (
        <Text
          size="sm"
          bold
          aria-label={title}
        >
          <Link
            href={url}
            underline={false}
            target={newTab ? '_blank' : '_self'}
            rel={newTab ? 'noreferrer' : undefined}
          >
            {title}
          </Link>
        </Text>
      )}
      {!!body && (
        <Text
          size="sm"
          bold={false}
          aria-label={title}
        >
          <Link
            href={url}
            underline={false}
            target={newTab ? '_blank' : '_self'}
            rel={newTab ? 'noreferrer' : undefined}
          >
            {body}
          </Link>
        </Text>
      )}
      {callToActionText && (
        <Text
          size="sm"
          bold
          aria-label={title}
        >
          <Link
            href={url}
            underline={false}
            target={newTab ? '_blank' : '_self'}
            rel={newTab ? 'noreferrer' : undefined}
          >
            {callToActionText}
          </Link>
        </Text>
      )}
    </Row>
  </div>
)

const sharedClasses = 'absolute top-full py-6 px-8'
const megaNavExpandedContent = `${sharedClasses} max-w-full`
const fullWidthMegaNavExpandedContent = `${sharedClasses} min-w-full left-0 max-w-full`
interface MegaNavItemProps extends megaNavItem, SiblingReferer {
  closeOnNavigation: boolean
  index: number,
  className: string,
}

const MegaNavItem: React.FC<MegaNavItemProps> = ({
  fullWidth = true,
  textColor,
  style = textColor ? { color: textColor } : undefined,
  columns = [],
  promotion,
  title = '',
  id = '',
  url = '',
  siblingsRefs,
  megaNavTrackRef,
  closeOnNavigation,
  className,
  index,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [rightAlign, setRightAlign] = useState<boolean>(false)
  const [visited, setVisited] = useState(false)
  const parentRef = siblingsRefs[index]
  const [expandedSectionRef, setExpandedSectionRef] = useState<HTMLDivElement | null>(null)
  const setOpen = () => {
    setIsExpanded(true)
    if (!visited) {
      setVisited(true)
    }
  }
  const setClosed = () => setIsExpanded(false)
  const menuEvents: MenuEventsType = {
    onMouseEnter: setOpen,
    onMouseLeave: setClosed,
    onFocus: setOpen,
    onBlur: (e) => {
      const isNavigatingToChildComponent = (
        parentRef?.ref?.contains((e?.nativeEvent?.relatedTarget as Node))
      )
      if (!isNavigatingToChildComponent) {
        setClosed()
      }
    },
  }

  const locationHref = (typeof window !== 'undefined' && window.location.pathname) || ''
  useEffect(() => {
    if (closeOnNavigation) {
      // Custom links might not reload the page therefore we close on navigation
      setIsExpanded(false)
    }
  }, [locationHref, closeOnNavigation])

  useEffect(() => {
    if (megaNavTrackRef && expandedSectionRef) {
      const parentRight = (megaNavTrackRef.getBoundingClientRect()).right || window.innerWidth
      const childRight = (expandedSectionRef.getBoundingClientRect()).right || window.innerWidth
      setRightAlign(isExpanded && (parentRight <= childRight))
    }
  }, [isExpanded, megaNavTrackRef, expandedSectionRef])

  const maxRight = useMemo<number>(() => {
    if (typeof window !== 'undefined' && isExpanded) {
      return window?.innerWidth * 0.95
    }
    return 0
  }, [isExpanded])
  const maxWidth = useMemo<number>(() => {
    const left = parentRef.ref?.getBoundingClientRect()?.left || 0
    return maxRight - left
  }, [parentRef, maxRight])
  const shouldAlignRight = useMemo(() => !fullWidth && rightAlign, [fullWidth, rightAlign])
  const expandedSectionId = useMemo(() => id || `${title} - Item ${index}`, [id, title, index])
  return (
    <li
      ref={parentRef.setter}
      {...menuEvents}
      role="treeitem"
      aria-expanded={isExpanded}
      aria-controls={expandedSectionId}
      aria-label={title}
      className="group text-center h-fit"
    >
      <Text
        className={`${className} relative px-1 focus:outline-none`}
        style={style}
        aria-label={title}
      >
        <Link
          underline={false}
          className={`whitespace-nowrap relative${url ? '' : 'cursor-default'}`}
          href={url}
        >
          {title}
        </Link>
        <span className="hidden group-hover:block absolute bottom-1 left-0 h-1 w-full bg-secondary" />
      </Text>
      <div className="hidden group-hover:block absolute top-7 left-0 w-full h-5 z-200" />

      <div
        style={fullWidth ? undefined : { maxWidth: shouldAlignRight ? '90%' : maxWidth }}
        id={expandedSectionId}
        ref={setExpandedSectionRef}
        aria-hidden={!isExpanded}
        className={`group-hover:block overflow-bar bg-white megaNav-shadow ${fullWidth ? fullWidthMegaNavExpandedContent : megaNavExpandedContent} ${shouldAlignRight ? ' right-0 lg:right-5p' : ''} z-20 ${isExpanded ? 'block' : 'hidden'} overflow-x-auto  ${fullWidth ? 'min-w-full left-0 max-w-full' : maxWidth}`}
      >
        {!isExpanded ? null : (
          <div
            className="flex m-auto justify-evenly min-w-fit"
          >
            {columns?.filter(isDefined)?.map((c, columnIndex) => (
              <MegaNavColumn
                {...c}
                key={
                  typeof c === 'object'
                    ? JSON.stringify(c)
                    : String(c)
                }
                menuEvents={menuEvents}
                index={columnIndex}
                isLastColumn={columnIndex === columns.length - 1}
              />
            ))}
            {!!promotion && visited && (
              <PromotionColumn {...promotion} />
            )}
          </div>
        )}
      </div>
    </li>
  )
}

interface DesktopMegaNavItemsProps {
  items: megaNavItem[],
  desktopBreakpoint?: BreakPoint,
  className?: string,
  closeOnNavigation: boolean,
  itemsClassName: string,
}

const DesktopMegaNavItems: React.FC<DesktopMegaNavItemsProps> = ({
  items,
  desktopBreakpoint = 'lg',
  className = '',
  closeOnNavigation,
  itemsClassName,
}) => {
  const refs = items?.filter(isDefined)?.map(() => {
    const [ref, setter] = useState<HTMLLIElement | null>(null)
    return {
      ref,
      setter,
    }
  })
  const [megaNavTrackRef, setMegaNavTrackRef] = useState<HTMLElement | null>(null)
  return (
    <nav
      ref={setMegaNavTrackRef}
      className={`hidden ${desktopBreakpoint}:block w-full`}
      id="DesktopMegaNav_Main_track"
      data-testid="DesktopMegaNav_Main_track"
    >
      <ul role="tree" className={`${className} flex items-center content-center justify-between overflow-hidden`}>
        {items?.filter(isDefined)?.map((c, index) => (
          <MegaNavItem
            closeOnNavigation={closeOnNavigation}
            megaNavTrackRef={megaNavTrackRef}
            key={`${String(c.url)}->${index + 1}`}
            className={itemsClassName}
            siblingsRefs={refs}
            index={index}
            {...c}
          />
        ))}
      </ul>
    </nav>
  )
}

export default DesktopMegaNavItems
