/* eslint-disable */
import { error } from '@/services/Log'
import React from 'react'

export interface IProps {
  customFallBackUi: React.ReactNode
  children: React.ReactNode
}
export interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(err: Error): IState {
    error(`ErrorBoundary: ${String(err)}`, err)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render some custom fallback UI
      return this.props.customFallBackUi
    }

    return this.props.children
  }
}
const defaultErrorUI = <h1>Something went wrong.</h1>
export function withErrorHandled<T extends object>(Component: React.ComponentType<T>, customFallBackUi: React.ReactNode = defaultErrorUI) {
  function ErrorHandled(props: T) {
    return (
      <ErrorBoundary customFallBackUi={customFallBackUi}>
        <Component {...props as T} />
      </ErrorBoundary>
    )
  }
  return ErrorHandled as React.FC<T>
}

export default withErrorHandled
