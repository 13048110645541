/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react'
import { withErrorHandled } from '@/components/dls/ErrorHandler'

import type { MegaNavProps, megaNavItem } from './types'
import MobileMegaNav from './MobileMegaNav'
import DesktopMegaNav from './DesktopMegaNav'

type DeviceType = 'desktop' | 'mobile'
const DEVICES: {
  DESKTOP: DeviceType
  MOBILE: DeviceType

} = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}

const filterTargetPlatform: (
  // imported from DLS as any :(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any, deviceType: DeviceType
) => megaNavItem[] = (obj, deviceType) => {
  if (Array.isArray(obj?.devices) && !obj.devices.includes(deviceType)) {
    return undefined
  }
  if (Array.isArray(obj?.devices)) {
    delete obj.devices
  }
  if (Array.isArray(obj)) {
    return obj.map((subObject) => filterTargetPlatform(subObject, deviceType))?.filter((i) => !!i)
  }
  if (typeof obj === 'object' && !React.isValidElement(obj)) {
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key] || typeof obj[key] === 'object')) {
        obj[key] = filterTargetPlatform(obj[key], deviceType)
      }
      if (!obj[key]) {
        delete obj[key]
      }
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return obj
}

export const MegaNav: React.FC<MegaNavProps> = ({ megaNavItems, ...props }) => {
  const desktopItems = useMemo(() => (
    filterTargetPlatform(megaNavItems, DEVICES.DESKTOP)
  ), [megaNavItems])
  const mobileItems = useMemo(() => (
    filterTargetPlatform(megaNavItems, DEVICES.MOBILE)
  ), [megaNavItems])
  return (
    <>
      <MobileMegaNav megaNavItems={mobileItems} {...props} />
      <DesktopMegaNav megaNavItems={desktopItems} {...props} />
    </>
  )
}

export default withErrorHandled(MegaNav)
