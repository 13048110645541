/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import dynamic from 'next/dynamic'
import { useIsAtTheTop } from '@csc/dls/hooks'
import { withErrorHandled } from '@/components/dls/ErrorHandler'
import DesktopMegaNavItems from './DesktopMegaNavItems'
import type { MegaNavProps } from './types'

const DesktopTopBar = dynamic(import('./DesktopTopBar'))
const Link = dynamic(import('@/components/dls/Link'))
const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const SearchIcon = dynamic(import('@csc/dls/Icons/SearchIcon'))

const DesktopMegaNav: React.FC<MegaNavProps> = ({
  logo,
  megaNavItems: items = [],
  headerLinks = [],
  maxWidth = 'lg:max-w-90p',
  searchBarProps = {
    value: '',
    name: 'MegaNav_searchBar',
    height: 'h-10',
    placeholder: 'Search',
    label: 'Search',
    role: 'search',
    'aria-label': 'Search bar',
  },
  searchBar = (
    <div className="relative">
      <AlphanumericInput {...searchBarProps} />
      <IconRenderer IconComponent={SearchIcon} size="sm" className="absolute right-0 transform top-1/2 -translate-y-1/2 mr-2" />
    </div>
  ),
  desktopBreakpoint = 'lg',
  closeOnNavigation = true,
  smallLogo,
  topContainerClassNames = '',
  isEventBannerVisible,
  stickyContainerClassNames = '',
}) => {
  const isAtTheTop = useIsAtTheTop({
    id: 'DesktopMegaNav',
  })

  return (
    <>
      <div className={`${topContainerClassNames} hidden ${desktopBreakpoint}:block ${isAtTheTop ? 'max-h-screen' : 'overflow-hidden max-h-0'}`}>
        <DesktopTopBar
          maxWidth={maxWidth}
          logo={(
            <Link href="/" className="mx-auto cursor-pointer">
              {typeof logo === 'string'
                ? (
                  <img
                    style={{
                      maxWidth: 'unset',
                      minWidth: 'auto',
                      width: 'auto',
                      minHeight: '64px',
                      height: '64px',
                      maxHeight: '64px',
                    }}
                    className="mx-auto"
                    src={logo}
                    alt="Company-logo"
                  />
                )
                : logo}
            </Link>
          )}
          searchBar={searchBar}
          headerLinks={headerLinks}
        />
      </div>
      <div className={`${stickyContainerClassNames} z-100 hidden relative ${desktopBreakpoint}:flex items-center flex-row max-w-full min-w-full w-full ${isAtTheTop ? 'bg-meganav-track' : `px-4 xl:px-16 bg-white megaNav-shadow sticky top-${isEventBannerVisible ? '9' : '0'}`}`}>
        {!isAtTheTop && (
          <Link href="/" className="cursor-pointer">
            {typeof smallLogo === 'string'
              ? (
                <img
                  style={{
                    maxWidth: 'unset',
                    minWidth: 'auto',
                    width: 'auto',
                    minHeight: '35px',
                    height: '35px',
                    maxHeight: '35px',
                  }}
                  src={smallLogo}
                  alt="Company-logo"
                />
              )
              : smallLogo}
          </Link>
        )}
        <DesktopMegaNavItems
          itemsClassName={isAtTheTop ? 'py-4' : 'py-10'}
          closeOnNavigation={closeOnNavigation}
          desktopBreakpoint={desktopBreakpoint}
          className={`overflow-visible ${isAtTheTop ? 'm-auto max-w-90p h-12' : 'h-24 px-4 xl:px-8'}`}
          items={items}
        />
        {!isAtTheTop && (
          <div className="hidden xl:block">
            {searchBar}
          </div>
        )}
      </div>
    </>
  )
}

export default withErrorHandled(DesktopMegaNav)
