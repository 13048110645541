/* eslint-disable react/jsx-props-no-spreading */
import dynamic from 'next/dynamic'

import { withErrorHandled } from '@/components/dls/ErrorHandler'
import { useScrollWatcher } from '@csc/dls/hooks'
import type { MegaNavProps } from './types'

const MobileMegaNavExpandedMenu = dynamic(import('./MobileMegaNavExpandedMenu'))
const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const BasketIcon = dynamic(import('@csc/dls/Icons/Basket'))
const SearchIcon = dynamic(import('@csc/dls/Icons/SearchIcon'))
const Link = dynamic(import('@/components/dls/Link'))
const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))

const MegaNav: React.FC<MegaNavProps> = ({
  logo,
  megaNavItems: items = [],
  maxWidth = 'lg:max-w-90p',
  mobileNavIcons = [
    <IconRenderer key={1} IconComponent={BasketIcon} size="md" />,
  ],
  searchBarProps = {
    value: '',
    name: 'MegaNav_searchBar',
    height: 'h-10',
    placeholder: 'Search',
    label: 'Search',
    role: 'search',
    'aria-label': 'Search bar',
  } as const,
  searchBar = (
    <div className="relative">
      <AlphanumericInput {...searchBarProps} />
      <IconRenderer IconComponent={SearchIcon} size="sm" className="absolute right-0 transform top-1/2 -translate-y-1/2 mr-2" />
    </div>
  ),
  headerLinks = [],
  desktopBreakpoint = 'lg',
  closeOnNavigation = true,
  smallLogo,
  forceMobileExpandedState = false,
  isEventBannerVisible,
  ...rest
}) => {
  const { isAtTheTop, isScrollingUp } = useScrollWatcher({
    id: 'MobileMegaNav',
  })

  const Logo = (
    <Link underline={false} href="/" className="mx-auto cursor-pointer">
      {typeof logo === 'string' ? (
        <img
          style={{
            maxWidth: 'unset',
            minWidth: 'auto',
            width: 'auto',
            minHeight: '64px',
            height: '64px',
            maxHeight: '64px',
          }}
          className="mx-auto"
          src={logo}
          alt="Company-logo"
        />
      ) : logo}
    </Link>
  )

  const SmallLogo = (
    <Link underline={false} href="/" className="mx-auto cursor-pointer">
      {typeof smallLogo === 'string'
        ? (
          <img
            style={{
              maxWidth: 'unset',
              minWidth: 'auto',
              width: 'auto',
              minHeight: '35px',
              height: '35px',
              maxHeight: '35px',
            }}
            className="mx-auto"
            src={smallLogo}
            alt="Company-logo"
          />
        )
        : smallLogo}
    </Link>
  )
  const shouldShowSearchBar = (forceMobileExpandedState || isAtTheTop || isScrollingUp)
  return (
    <>
      <div className={`lg:hidden p-4 pt-2 megaNav-shadow bg-white z-100 sticky top-${isEventBannerVisible ? '9' : '0'} min-w-45 min-h-5 `} id="mobile-top-navigation">
        <Row gapY="xs" cols={1}>
          <Col>
            <div
              className="flex flex-row items-center justify-spread align-middle"
              style={{
                height: isAtTheTop ? '80px' : '43px',
                paddingTop: isAtTheTop ? '8px' : undefined,
              }}
            >
              <div className="flex justify-center align-middle w-12">
                <MobileMegaNavExpandedMenu
                  megaNavItems={items}
                  logo={logo}
                  maxWidth={maxWidth}
                  mobileNavIcons={mobileNavIcons}
                  searchBarProps={searchBarProps}
                  searchBar={searchBar}
                  headerLinks={headerLinks}
                  desktopBreakpoint={desktopBreakpoint}
                  closeOnNavigation={closeOnNavigation}
                  smallLogo={smallLogo}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...rest}
                />
              </div>
              <div className="min-w-fit flex-grow">
                {isAtTheTop ? Logo : SmallLogo}
              </div>
              <div className="flex justify-end align-middle w-12">
                {mobileNavIcons.map((i) => <div key={`mobileNavIcons-${String(i)}`}>{i}</div>)}
              </div>
            </div>
          </Col>
          <Col className={shouldShowSearchBar ? 'block' : 'hidden'}>
            {searchBar}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withErrorHandled(MegaNav)
